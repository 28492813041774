.booklet_resume{
    display: "flex" !important;
}

.booklet_resume > .rs-panel-collapsible > .rs-panel-heading::before{
    position: unset !important;
}

.booklet_resume > .rs-panel-heading {
    display: flex;
    flex-direction: row-reverse;
}

.booklet_resume > .rs-panel-heading > .rs-panel-title{
    margin-right: 20px;
}